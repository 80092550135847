<template>
  <div class="section">
    <div class="transaction__filter--container d-block bg-white py-2">
      <b-row class="search__export align-items-center mx-0">
        <b-col cols="6" md="6" lg="9">
          <div class="d-flex pl-2">
            <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
              @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'" />
            <div class="search__input w-100 pl-2 pr-0 mr-0">
              <b-input-group>
                <b-form-input v-model="filter.search" placeholder="Cari Billing" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-col>
        <b-col cols="6" md="6" lg="3">
          <div class="d-flex" style="gap: 5px">
            <b-button class="py-1 border-8 font-small-3" @click="$bvModal.show('modal-subscribe')">
              Tambah Berlangganan
            </b-button>
            <b-button class="py-1 border-8" @click="$bvModal.show('modal-billing-branch-and-user')">
              Tambah Slot Cabang/User
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <vue-good-table v-if="items" max-height="80vh" :columns="columns" :rows="items" :fixed-header="false" :sort-options="{
                enabled: false
              }" :pagination-options="{
                enabled: false,
              }">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Status -->
        <span v-if="props.column.field === 'payment_status'">
          <span :class="{
                'badge badge-light-success': props.row.payment_status === 'success',
                'badge badge-light-warning': props.row.payment_status === 'pending',
                'badge badge-light-danger': props.row.payment_status === 'canceled',
                'badge badge-light-secondary': props.row.payment_status === 'expired',
              }">
            {{
                props.row.payment_status === 'success' ? 'Sukses' :
                  props.row.payment_status === 'pending' ? 'Menunggu Pembayaran' :
                    props.row.payment_status === 'canceled' ? 'Dibatalkan' : 'Kedaluwarsa'
              }}
          </span>
        </span>

        <!-- Column Price -->
        <span v-else-if="props.column.field === 'subscription_renewal.sell_price'">
          <span>{{ props.row.subscription_renewal.sell_price | formatAmount }}</span>
        </span>

        <!-- Column: Metode Bayar -->
        <span v-else-if="props.column.field === 'payment_method'">
          <span>
            {{
                props.row.payment_method === 'qr_code' ? 'QRIS' :
                  props.row.payment_method === 'bca_va' ? 'BCA Virtual Account' : 'Mandiri Virtual Account'
              }}
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown id="dropdown-dropleft" class="d-flex" right>
              <template #button-content class="btn-white text-center">
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" />
              </template>
              <b-dropdown-item @click="detailItem(props.row)">
                <span class="pr-5">
                  Detail Data
                </span>
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.payment_status === 'pending'" @click="cancelOrder(props.row.uuid)">
                <span class="pr-5">
                  Batalkan Transaksi
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div v-if="items" class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan {{ (rows.data.total > 0) ? (filter.per_page * (rows.data.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.data.total > 0) ? Math.min(filter.per_page * rows.data.current_page, rows.data.total) : 0 }} dari {{
                rows.data.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows.data" :limit="4" align="right"
              @pagination-change-page="getListSubscription" />
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalSubscribe />
    <ModalBillingBranchAndUser />
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow, BCol, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import ModalSubscribe from '../Master/Toko/Modal/ModalSubscribe.vue'
import ModalBillingBranchAndUser from '../Master/Toko/Modal/ModalBillingBranchAndUser.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    ModalSubscribe,
    ModalBillingBranchAndUser
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  props: {
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object,
    },
    getListSubscription: {
      type: Function,
    },
  },
  data() {
    const { merchant } = JSON.parse(localStorage.getItem('userData'))
    const { branch_slot, user_slot } = merchant
    return {
      branch_total_registered: 0,
      branch_slot,
      user_slot,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'No. Invoice',
          field: 'invoice_number',
        },
        {
          label: 'Jumlah',
          field: 'subscription_renewal.sell_price',
        },
        {
          label: 'Jenis Paket',
          field: 'subscription_renewal.renewal_name',
        },
        {
          label: 'Metode Bayar',
          field: 'payment_method',
        },
        {
          label: 'Berlaku Hingga',
          field: 'payment_expired_at',
        },
        {
          label: 'Status',
          field: 'payment_status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      items: [],
      search: '',
      filter: {
        search: '',
        sort_type: 'asc',
        per_page: 10,
      },
    }
  },
  watch: {
    result(value) {
      this.rows = value
      this.items = value.data.data
    },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.handleCta()
    // this.getCabang()
  },
  methods: {
    handleCta() {
      const { cta } = this.$route.query
      if (cta) {
        setTimeout(() => {
          this.$bvModal.show(cta)
        }, 500);
      }
    },
    // getCabang() {
    //   this.$store.dispatch('cabang/getData', {
    //     uuid: '',
    //     params: '',
    //   }).then(result => {
    //     this.branch_total_registered = result.data.data.total
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // },
    detailItem(row) {
      const uuid = row.uuid;

      this.$router.push({
        name: 'billing.index',
        params: { id: uuid },
      });
    },
    cancelOrder(id) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin ingin membatalkan transaksi berlangganan ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$store.dispatch('subscribe/deleteData', {
            uuid: `${id}`,
          }).then(() => {
            this.isLoading = false
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Order berhasil dibatalkan',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getListSubscription()
          }).catch(err => {
            this.isLoading = false
            // eslint-disable-next-line no-console
            console.log(err)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #fff;
}
</style>
