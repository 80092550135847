<template>
    <b-modal id="modal-billing-branch-and-user" hide-header hide-footer ok-only ok-title="Accept" centered size="lg"
        title="Large Modal" class="custom__modal--large">
        <div class="section">
            <ModalHeader v-if="step === 1" :left-text="'Kembali'" :middle-text="'Pilih Paket Berlangganan'"
                :is-rigt-column="true" :right-text="'Selanjutnya'" :right-function="nextSubmit"
                :left-function="prevPage" :isDisabled="false" />
            <ModalHeader v-else-if="step === 2" :left-text="'Kembali'" :middle-text="'Pilih Metode Pembayaran'"
                :is-rigt-column="true" :right-text="'Bayar'" :right-function="createSubscription"
                :left-function="prevPage" :isDisabled="isPaymentSelected" />
            <!-- Spinner -->
            <b-col v-if="isLoading == true" class="d-flex justify-content-center mt-5 pt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Loading..." />
            </b-col>
            <b-container v-else class="mt-15">
                <!-- Step 1 -->
                <b-container v-if="step === 1">
                    <b-row class="justify-content-center">
                        <b-col cols="12" md="10" lg="8">
                            <b-row class="bg-white">
                                <b-col class="mb-2 custom__form--input">
                                    <label for="v-bank">Jenis Penambahan <span class="text-danger">*</span></label>
                                    <b-form-group>
                                        <v-select v-model="payloadSubscription.type" label="label"
                                            :options="optionsAddition" :reduce="(option) => option.value"
                                            placeholder="Pilih Penambahan" />
                                        <!-- <small
                                    v-if="messages.account_type && Array.isArray(messages.account_type) && messages.account_type.length > 0"
                                    class="text-error fw-bold-500 size12">{{ messages.account_type.join(', ') }}</small> -->
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="bg-white">
                                <b-col cols="4" class="mb-2 custom__form--input">
                                    <label for="v-bank" class="text-capitalize">Jumlah {{ type[payloadSubscription.type]
                || 'Cabang/User' }}
                                        <span class="text-danger">*</span></label>
                                    <b-form-group style="width: fit-content;">
                                        <!-- <b-form-input id="input-branch-user" type="number" class="custom__input" /> -->
                                        <b-form-spinbutton id="demo-sb" v-model="payloadSubscription.count" min="1"
                                            max="100" class="custom__input" />
                                        <!-- <small
                                v-if="messages.account_type && Array.isArray(messages.account_type) && messages.account_type.length > 0"
                                class="text-error fw-bold-500 size12">{{ messages.account_type.join(', ') }}</small> -->
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="bg-white">
                                <b-col class="mb-2 custom__form--input">
                                    <label for="v-bank">Paket Berlangganan <span class="text-danger">*</span></label>
                                    <b-form-group>
                                        <v-select v-model="payloadSubscription.subscription_package"
                                            label="renewal_name" :options="optionsSubcription"
                                            placeholder="Pilih Paket">
                                            <template #option="{ renewal_name, price, sell_price }">
                                                <span>{{ renewal_name }} <span v-if="price > sell_price"
                                                        style="text-decoration: line-through">{{ price | formatAmount
                                                        }}</span>&nbsp;<span>{{ sell_price | formatAmount
                                                        }}</span></span>
                                            </template>
                                        </v-select>
                                        <!-- <small
                                    v-if="messages.account_type && Array.isArray(messages.account_type) && messages.account_type.length > 0"
                                    class="text-error fw-bold-500 size12">{{ messages.account_type.join(', ') }}</small> -->
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="mt-4">
                                <h4 class="font-weight-bold">Total dibayar</h4>
                                <h1 class="font-weight-bold">{{ payloadSubscription.subscription_package.sell_price |
                formatAmount }}</h1>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- Step 2 -->
                <b-container v-if="step === 2">
                    <b-row class="justify-content-center h-100 bg-white pb-5">
                        <b-col cols="10" md="6" lg="3">
                            <b-card class="card__subscription border-15 bg-secondary text-white text-center">
                                <div class="discount-tag fw-bold-900 bg-warning">
                                    <div>Best Offer</div>
                                </div>
                                <div class="mb-2">{{ payloadSubscription.subscription_package.renewal_name }}</div>
                                <div class="mb-2">{{ payloadSubscription.subscription_package.renewal_description }}
                                </div>
                                <!-- <div class="mt-4 size24 fw-bold-600">Hemat {{
                getSelectedSubscription('discount_percent') }}%</div> -->

                                <template #footer>
                                    <div class="strike-through">{{ payloadSubscription.subscription_package.price |
                formatAmount }}
                                    </div>
                                    <div class="mb-2 size22">{{ payloadSubscription.subscription_package.sell_price |
                formatAmount }}</div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row class="justify-content-center bg-white pb-2">
                        <b-col cols="12" md="6" lg="3" class="d-flex justify-content-center mb-3">
                            <label for="bca_va" class="w-75 cursor-pointer">
                                <b-card class="" @click="selectPayment('bca_va')">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <b-form-radio id="bca_va" v-model="payloadSubscription.payment_method"
                                            name="bca_va" value="bca_va" />
                                        <b-img :src="require('/src/assets/images/logo/logo-bca.png')" alt="bca" />
                                    </div>
                                </b-card>
                            </label>
                        </b-col>
                        <b-col cols="12" md="6" lg="3" class="d-flex justify-content-center mb-3">
                            <label for="mandiri_va" class="w-75 cursor-pointer">
                                <b-card class="" @click="selectPayment('mandiri_va')">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <b-form-radio id="mandiri_va" v-model="payloadSubscription.payment_method"
                                            name="mandiri_va" value="mandiri_va" />
                                        <b-img :src="require('/src/assets/images/logo/logo-mandiri.png')"
                                            alt="mandiri" />
                                    </div>
                                </b-card>
                            </label>
                        </b-col>
                        <b-col cols="12" md="6" lg="3" class="d-flex justify-content-center mb-3">
                            <label for="qr_code" class="w-75 cursor-pointer">
                                <b-card class="" @click="selectPayment('qr_code')">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <b-form-radio id="qr_code" v-model="payloadSubscription.payment_method"
                                            name="qr_code" value="qr_code" />
                                        <b-img :src="require('/src/assets/images/logo/logo-qris.png')" alt="qris" />
                                    </div>
                                </b-card>
                            </label>
                        </b-col>
                    </b-row>

                </b-container>
            </b-container>
        </div>
    </b-modal>
</template>

<script>
import {
    BModal, BContainer, BRow, BCol, BFormGroup, BFormInput, BImg, BCard, BButton, BFormCheckbox, BFormRadio, BSpinner, BFormSpinbutton
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ModalHeader from '@/components/ModalHeader.vue'

export default {
    components: {
        BModal,
        BRow,
        BCol,
        vSelect,
        BImg,
        BCard,
        BButton,
        BFormGroup,
        BFormInput,
        BContainer,
        BFormCheckbox,
        BFormRadio,
        BSpinner,
        ModalHeader,
        BFormSpinbutton
    },
    data() {
        return {
            value: 1,
            step: 1,
            subscription: [],
            selectedId: '',
            selectedPayment: '',
            isLoading: false,
            selectedAddition: null,
            payloadSubscription: {
                type: '',
                count: 1,
                subscription_package: '',
                count: 1,
                payment_method: ''
            },
            optionsAddition: [
                {
                    label: 'Tambah Cabang', value: 'branch',
                },
                {
                    label: 'Tambah User', value: 'user',
                },
            ],
            optionsSubcription: [],
            type: {
                branch: 'Cabang',
                user: 'User'
            }
        }
    },
    setup() {
        return {
            checkPermission, successNotification, errorNotification,
        }
    },
    created() {
        this.getItems()
    },
    computed: {
        hasSelectedItem() {
            return this.selectedId !== '';
        },
        isNextButtonDisabled() {
            return !this.hasSelectedItem;
        },
        isPaymentSelected() {
            return !this.selectedPayment;
        },
        highestDiscountSubscription() {
            if (this.subscription.length === 0) {
                return [];
            }

            // Find the subscription with the highest discount_percent
            const highestDiscount = Math.max(
                ...this.subscription.map((item) => item.discount_percent)
            );
            console.log(highestDiscount, 'highestDiscount');
            return highestDiscount;
        },
    },
    watch: {
        'payloadSubscription.type': 'getPackageBranchUser',
        'payloadSubscription.count': 'getPackageBranchUser',
    },
    mounted() {

    },
    methods: {
        getPackageBranchUser() {
            const { type, count } = this.payloadSubscription
            const queryParams = { type, count }
            this.$store.dispatch('subscribe/getPackageBranchUser', {
                params: queryParams
            })
                .then(result => {
                    console.log(result.data.data, 'RESULT');
                    this.optionsSubcription = result.data.data
                }).catch(err => {
                    console.log(err)
                })
        },
        async getItems() {
            this.$store.dispatch('subscribe/getData')
                .then(result => {
                    this.subscription = result.data.data
                }).catch(err => {
                    console.log(err)
                })
        },
        createSubscription() {
            console.log(this.payloadSubscription, 'payloadSubscription');
            if (this.step === 2) {
                if (!this.selectedPayment) {
                    errorNotification('Silahkan pilih metode pembayaran terlebih dahulu.');
                    return;
                }

                this.$swal({
                    title: 'Konfirmasi',
                    text: 'Apakah Anda yakin dengan pilihan berlangganan ini?',
                    icon: 'warning',
                    showCancelButton: true,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.isLoading = true;
                        const payload = {
                            subscription_renewal_id: this.payloadSubscription.subscription_package.id,
                            payment_method: this.payloadSubscription.payment_method,
                        };
                        this.$store.dispatch('subscribe/postSubscription', { payload })
                            .then(response => {
                                this.$bvModal.hide('modal-subscribe');
                                const uuid = response.data.data.uuid;

                                this.$router.push({
                                    name: 'billing.index',
                                    params: { id: uuid },
                                });
                            })
                            .catch(error => {
                                this.isLoading = false;
                                errorNotification('Error creating subscription.');
                                console.error(error);
                            });
                    }
                });
            }
        },
        selectSubscription(id) {
            this.selectedId = id;
        },
        selectPayment(paymentMethod) {
            this.selectedPayment = paymentMethod;
        },
        nextSubmit() {
            if (this.step === 1) {
                this.step = 2;
            }
        },
        prevPage() {
            if (this.step === 2) {
                this.step = 1;
            } else {
                this.$bvModal.hide('modal-billing-branch-and-user')
            }
        },
        getSelectedSubscription(propertyName) {
            const selectedRenewal = this.subscription.find(item => item.id === this.selectedId);
            return selectedRenewal ? selectedRenewal[propertyName] : '';
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>